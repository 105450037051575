import { Box, Flex, Button, Text, Select, useToast } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import FormatIndianRupees from "../../Functions/FormatIndianRupees";
import Logo from "../../../src/Assets/Images/StylHomLOGOpng.webp";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_BASE_URL } from "../../Config";
import { config } from "../../Functions/Auth";
import { getItemFromLocalStorage } from "../../utils/local-storage";
function DesignerSubscription(props) {
  const client = getItemFromLocalStorage("client");
  const [subscriptionOption, setSubscriptionOption] = useState("1");
  const queryClient = useQueryClient();
  const [Discount, setDiscount] = useState(0);
  const toast = useToast();
  const handleSubscriptionChange = (event) => {
    setSubscriptionOption(event.target.value);

    // Update Discount based on the selected option
    if (event.target.value === "6") {
      setDiscount(10);
    } else if (event.target.value === "12") {
      setDiscount(20);
    } else {
      setDiscount(0); // Default discount for other options
    }
  };

  const calculatePremiumPlanPrice = () => {
    let basePrice;

    if (subscriptionOption === "6") {
      // 6 Months subscription
      basePrice = 450 * 6;
    } else if (subscriptionOption === "12") {
      // 12 Months subscription
      basePrice = 450 * 12;
    } else {
      // Default price for other options
      basePrice = 450;
    }

    // Calculate the discounted price
    const discountedPrice = basePrice - (basePrice * Discount) / 100;

    return discountedPrice;
  };
  // Razorpay
  const [orderAmount, setOrderAmount] = useState(0);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  async function fetchOrders() {
    const { data } = await axios.get(`${API_BASE_URL}/razorpay/list-orders`);
    setOrders(data);
  }
  useEffect(() => {
    fetchOrders();
  }, []);

  function loadRazorpay(plan) {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onerror = () => {
      alert("Razorpay SDK failed to load. Are you online?");
    };
    script.onload = async () => {
      try {
        setLoading(true);
        const result = await axios.post(
          `${API_BASE_URL}/razorpay/create-order`,
          {
            amount: calculatePremiumPlanPrice() + "00",
          }
        );
        const { amount, id: order_id, currency } = result.data;
        const {
          data: { key: razorpayKey },
        } = await axios.get(`${API_BASE_URL}/razorpay/get-razorpay-key`);

        const options = {
          key: razorpayKey,
          amount: amount.toString(),
          currency: currency,
          name: "Lokolumo",
          image: Logo,
          description: "Thanks for purchasing",
          order_id: order_id,
          handler: async function (response) {
            // get payment details
            const paymentId = response.razorpay_payment_id;
            const paymentDetails = await axios.get(
              `${API_BASE_URL}/razorpay/payment-details/${paymentId}`
            );
            // ............
            const result = await axios.post(
              `${API_BASE_URL}/razorpay/pay-order`,
              {
                amount: amount,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
                paymentMethod: paymentDetails.data.method,
                plan,
                subscriptionOption,
                userId: client._id,
                designer: true,
                method: paymentDetails?.data?.method,
              },
              config()
            );
            if (result?.data?.msg) {
              const response = await axios.post(
                `${API_BASE_URL}/designer/subscription`,
                { subscriptionOption, amount, plan },
                config()
              );
              if (response && response !== null) {
                toast({
                  title: "Subscription successful",
                  description: "Subscription Successful",
                  status: "success",
                  position: "top",
                  isClosable: true,
                });
                await queryClient.invalidateQueries("designer");
              }
            }
            fetchOrders();
          },
          prefill: {
            name: "",
            email: "",
            contact: "",
          },
          notes: {
            address: "",
          },
          theme: {
            color: "#C69C6D",
          },
        };

        setLoading(false);
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (err) {
        alert(err);
        setLoading(false);
      }
    };
    document.body.appendChild(script);
  }

  // Razorpay

  const {} = props;
  return (
    <Flex
      width={"100%"}
      px={{ base: "20px", md: "20px", lg: "0px" }}
      my={"20px"}
    >
      <Flex
        width={"100%"}
        borderRadius={"10px"}
        backgroundColor={"#E8E2DC"}
        boxShadow={"lg"}
        flexDir={"column"}
      >
        {/* header */}
        <Box
          borderTopLeftRadius={"10px"}
          borderTopRightRadius={"10px"}
          height={"100px"}
          backgroundColor={"#BAAA9B"}
        >
          <Flex
            fontSize={{ base: "18px", md: "18px", lg: "20px" }}
            alignItems={"center"}
            height={"100%"}
          >
            <Text ml={"20px"} fontFamily={"heading"}>
              Subscription
            </Text>
          </Flex>
        </Box>
        {/* Form area */}
        <Flex
          fontSize={{ base: "15px", md: "15px", lg: "16px" }}
          justifyContent={"center"}
          my={"5%"}
          width={"100%"}
        >
          <Flex flexDir={"column"}>
            <Select
              ml={"auto"}
              mr={"9%"}
              backgroundColor={"white"}
              height={"30px"}
              width={"140px"}
              placeholder="Per Month"
              value={subscriptionOption}
              onChange={handleSubscriptionChange}
            >
              <option value="6">6 Months</option>
              <option value="12">Per Year</option>
            </Select>
            <Flex
              flexDir={{ base: "column", md: "column", lg: "row" }}
              mx={"40px"}
              mt={"40px"}
            >
              {/* Basic Paln */}
              <Flex
                ml={{ base: "0px", md: "0px", lg: "0px" }}
                flexDir={"column"}
              >
                <Text mx={"auto"} mb={"20px"}>
                  {" "}
                  Basic Plan
                </Text>
                <Flex
                  mx={"auto"}
                  fontSize={{ base: "12px", md: "14px", lg: "15px" }}
                  flexDir={"column"}
                  borderTop={"9px solid #CAB683"}
                  borderRadius={"10px"}
                  boxShadow={"lg"}
                  backgroundColor={"white"}
                  height={{ base: "300px", md: "300px", lg: "410px" }}
                  width={"200px"}
                >
                  <Text
                    color={"#CAB683"}
                    fontSize={{ base: "30px", md: "30px", lg: "35px" }}
                    mt={"50px"}
                    mx={"auto"}
                  >
                    {FormatIndianRupees(0)}
                  </Text>
                  <Text mt={"20px"} mx={"auto"}>
                    ✔ 1 Profile Picture
                  </Text>

                  <Text mt={"20px"} mx={"auto"}>
                    ✔ 30 Project Images
                  </Text>
                </Flex>
              </Flex>

              {/* Premium Paln */}
              <Flex
                mt={{ base: "30px", md: "30px", lg: "0px" }}
                ml={{ base: "0px", md: "0px", lg: "50px" }}
                flexDir={"column"}
              >
                <Text mx={"auto"} mb={"20px"}>
                  {" "}
                  Premium Plan
                </Text>
                <Flex
                  mx={"auto"}
                  fontSize={{ base: "12px", md: "14px", lg: "15px" }}
                  flexDir={"column"}
                  borderTop={"9px solid #83C6CA"}
                  borderRadius={"10px"}
                  boxShadow={"lg"}
                  backgroundColor={"white"}
                  height={"410px"}
                  width={"200px"}
                >
                  <Text
                    color={"#83C6CA"}
                    fontSize={{ base: "30px", md: "30px", lg: "35px" }}
                    mt={"50px"}
                    mx={"auto"}
                  >
                    {FormatIndianRupees(calculatePremiumPlanPrice())}
                  </Text>
                  <Text
                    color={"#83C6CA"}
                    fontSize={{ base: "20px", md: "20px", lg: "25px" }}
                    mt={"10px"}
                    mx={"auto"}
                  >
                    {Discount}% Discount
                  </Text>

                  <Text mt={"20px"} mx={"auto"}>
                    ✔ 1 Profile Picture
                  </Text>
                  <Text mt={"20px"} mx={"auto"}>
                    ✔ 3 Latest Promo Video
                  </Text>
                  <Text mt={"20px"} mx={"auto"}>
                    ✔ 50 Project Images
                  </Text>
                  <Text
                    mt={"20px"}
                    mx={"auto"}
                    textAlign={"center"}
                    maxW={"140px"}
                  >
                    ✔ Social media links are visible to users
                  </Text>

                  <Button
                    boxShadow={"lg"}
                    mb={"10px"}
                    height={"30px"}
                    mt={"auto"}
                    backgroundColor={"#83C6CA"}
                    mx={"auto"}
                    disabled={loading}
                    onClick={() => loadRazorpay("premium")}
                    // onClick={() => displayRazorpay(calculatePremiumPlanPrice())}
                  >
                    Buy Now
                  </Button>
                </Flex>
              </Flex>
            </Flex>{" "}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default DesignerSubscription;
