import React, { useState } from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../Components/shared/CommonButton";
import FormInput from "../Components/FormInputs/FormInput.js";
import LoginForm from "../Screens/LoginFroms/LoginForm.js";
import {
  Button,
  Divider,
  AbsoluteCenter,
  Text,
  Flex,
  Box,
  Stack,
  chakra,
  useToast,
} from "@chakra-ui/react";
import Logo from "../../src/Assets/Images/StylHomLOGOpng.webp";
import Google from "../../src/Assets/Images/google.png";
import Loginbg from "../../src/Assets/Images/Headers/loginbg.webp";
import Loginb from "../../src/Assets/Images/Headers/loginb.webp";
import { Image, Switch } from "@chakra-ui/react";
import RoleForm from "./LoginFroms/RoleForm";
function Login({ setModalClose, wish }) {
  const [showSignUp, setShowSignUp] = useState(false);

  const toggleSignUp = () => {
    setShowSignUp(true); // Toggle the showSignUp state when the "Sign Up" button is clicked
  };
  const toggleLogin = () => {
    setShowSignUp(false); // Toggle the showSignUp state when the "Sign Up" button is clicked
  };
  return (
    <Flex
      borderRadius={"10px"}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      // backgroundImage={Loginb}
      backgroundColor={"#ECEDEA"}
      flexDir={{ base: "column", md: "row", lg: "row" }}
      
    >
     

      <Flex
     
     bgSize={"cover"}
        borderRadius={"10px"}
        width={{ base: "100%", md: "240px", lg: "250px" }}
        flexDir={"column"}
        height={{ base: "200px", md: "100%", lg: "100%" }}
        backgroundImage={Loginbg}
      >
        <Image
          mt={"90px"}
          ml={"auto"}
          mr={"auto"}
          width={{ base: "200px", md: "200px", lg: "200px" }}
          src={Logo}
        ></Image>
        <Box height="560px"></Box>
      </Flex >
     
      <Flex
    
        height={"100%"}
        // backgroundImage={Loginb}
        backgroundColor={"#ECEDEA"}
        borderRadius={"10px"}
        width={{ base: "100%", md: "400px", lg: "500px" }}
        justifyContent={"center"}
        alignItems={"center"}
        fontSize={{ base: "15px", md: "15px", lg: "16px" }}
        flexDir={"column"}
      >
        <Flex
          height={"100%"}
          mr={"10px"}
          boxShadow={"lg"}
          mt={"20px"}
          ml={"auto"}
        >
          <Button
            fontSize={{ base: "12px", md: "12px", lg: "12px" }}
            borderTopRightRadius={"0"}
            borderBottomRightRadius={"0"}
            bg={showSignUp ? "white" : "brand.btnBg"}
            // hoverBg="white"
            _hover={{ bg: "#574F48", color: "white" }}
            color={showSignUp ? "brand.btnBg" : "white"}
            height={"30px"}
            onClick={toggleLogin}
          >
            Login
          </Button>
          <Button
            fontSize={{ base: "12px", md: "12px", lg: "12px" }}
            borderTopLeftRadius={"0"}
            borderBottomLeftRadius={"0"}
            bg={showSignUp ? "brand.btnBg" : "white"}
            // hoverBg="brand.btnBg"
            _hover={{ bg: "#574F48", color: "white" }}
            color={showSignUp ? "white" : "brand.btnBg"}
            height={"30px"}
            onClick={toggleSignUp}
          >
            Sign Up
          </Button>
        </Flex>
        <Flex height={"100%"} width={"100%"}>
          {showSignUp ? (
            <RoleForm setModalClose={setModalClose} />
          ) : (
            <LoginForm wish={wish} setModalClose={setModalClose} />
          )}{" "}
        </Flex>
        {/* Conditionally render the sign-up form */}
      </Flex>
    </Flex>
  );
}

export default Login;
