import { Box, Flex, Button, Text, Select, useToast } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import FormatIndianRupees from "../../Functions/FormatIndianRupees";
import Logo from "../../../src/Assets/Images/StylHomLOGOpng.webp";
import axios from "axios";
import { API_BASE_URL } from "../../Config";
import { config } from "../../Functions/Auth";
import { useNavigate } from "react-router-dom";
import { useGetShopData } from "../../Functions/queries";
import CommonModal from "../../Components/CommonModal/CommonModal";
import { DeleteProduct, DeleteProductNOffer } from "./Shop/DeleteProductNOffer";
import { useQueryClient } from "@tanstack/react-query";
import { getItemFromLocalStorage } from "../../utils/local-storage";
function ShopSubscription({ subscription }) {
  const toast = useToast();
  const { shop } = useGetShopData();
  const navigate = useNavigate();
  const [subscriptionOption, setSubscriptionOption] = useState("1");
  const [finalPrice, setfinalPrice] = useState(0);
  const [Discount, setDiscount] = useState(0);
  const [modalClose, setModalClose] = useState();
  const client = getItemFromLocalStorage("client");
  const handleSubscriptionChange = (event) => {
    setSubscriptionOption(event.target.value);

    // Update Discount based on the selected option
    if (event.target.value === "6") {
      setDiscount(10);
    } else if (event.target.value === "12") {
      setDiscount(20);
    } else {
      setDiscount(0); // Default discount for other options
    }
  };

  const calculatePremiumPlanPrice = () => {
    let basePrice;
    let shopbasePrice;
    let rate;
    if (subscriptionOption === "6") {
      // 6 Months subscription
      shopbasePrice = 1999 * 6;
      basePrice = 2999 * 6;
    } else if (subscriptionOption === "12") {
      // 12 Months subscription
      shopbasePrice = 1999 * 12;
      basePrice = 2999 * 12;
    } else {
      // Default price for other options
      basePrice = 2999;
      shopbasePrice = 1999;
    }

    // Calculate the discounted price
    const discountedPrice = basePrice - (basePrice * Discount) / 100;
    const shopdiscountedPrice =
      shopbasePrice - (shopbasePrice * Discount) / 100;
    if (finalPrice === 1) {
      rate = shopdiscountedPrice;
    } else if (finalPrice === 2) {
      rate = discountedPrice;
    } else {
      rate = 0;
    }
    return {
      discountedPrice: discountedPrice,
      shopdiscountedPrice: shopdiscountedPrice,
      rate: rate,
    };
  };

  // basic button click
  const handleBasicButtonClick = () => {
    setfinalPrice(1);
    loadRazorpay();
  };

  // basic button click
  const handlePremiumButtonClick = () => {
    setfinalPrice(2);
    loadRazorpay();
  };

  // Razorpay
  const [orderAmount, setOrderAmount] = useState("0");
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  async function fetchOrders() {
    const { data } = await axios.get(`${API_BASE_URL}/razorpay/list-orders`);
    setOrders(data);
  }
  const [plan, setPlan] = useState();
  useEffect(() => {
    fetchOrders();
  }, []);

  function loadRazorpay(orderamount, plan) {
    let finalamount = Math.round(orderamount);
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onerror = () => {
      alert("Razorpay SDK failed to load. Are you online?");
    };
    script.onload = async () => {
      try {
        setLoading(true);
        const result = await axios.post(
          `${API_BASE_URL}/razorpay/create-order`,
          {
            amount: finalamount + "00",
          }
        );
        const { amount, id: order_id, currency } = result.data;
        const {
          data: { key: razorpayKey },
        } = await axios.get(`${API_BASE_URL}/razorpay/get-razorpay-key`);

        const options = {
          key: razorpayKey,
          amount: amount.toString(),
          currency: currency,
          name: "Lokolumo",
          image: Logo,
          description: "Thanks for purchasing",
          order_id: order_id,
          handler: async function (response) {
            // get payment details
            const paymentId = response.razorpay_payment_id;
            const paymentDetails = await axios.get(
              `${API_BASE_URL}/razorpay/payment-details/${paymentId}`
            );
            const result = await axios.post(
              `${API_BASE_URL}/razorpay/pay-order`,
              {
                amount: amount,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
                plan,
                subscriptionOption,
                userId: client._id,
                shop: true,
                method: paymentDetails?.data?.method,
              },
              config()
            );
            if (result?.data?.msg) {
              const response = await axios.post(
                `${API_BASE_URL}/shop/subscription`,
                { subscriptionOption, amount, plan },
                config()
              );
              if (response && response !== null) {
                toast({
                  title: "Subscription successful",
                  description: "Shop Subscription Successful",
                  status: "success",
                  position: "top",
                  isClosable: true,
                });
                await queryClient.invalidateQueries("shop");
              }
            }
            fetchOrders();
          },
          prefill: {
            name: "",
            email: "help@lokolumo.com",
            contact: "000",
          },
          notes: {
            address: "example address",
          },
          theme: {
            color: "#C69C6D",
          },
        };

        setLoading(false);
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (err) {
        alert(err);
        setLoading(false);
      }
    };
    document.body.appendChild(script);
  }
  // Razorpay
  return (
    <Flex
      width={"100%"}
      px={{ base: "20px", md: "20px", lg: "0px" }}
      my={"20px"}
    >
      <Flex
        width={"100%"}
        borderRadius={"10px"}
        backgroundColor={"#E8E2DC"}
        boxShadow={"lg"}
        flexDir={"column"}
      >
        {/* header */}
        <Box
          borderTopLeftRadius={"10px"}
          borderTopRightRadius={"10px"}
          height={"100px"}
          backgroundColor={"#BAAA9B"}
        >
          <Flex
            fontSize={{ base: "18px", md: "18px", lg: "20px" }}
            alignItems={"center"}
            height={"100%"}
          >
            <Text ml={"20px"} fontFamily={"heading"}>
              Subscription
            </Text>
          </Flex>
        </Box>
        {/* Form area */}
        <Flex
          fontSize={{ base: "15px", md: "15px", lg: "16px" }}
          justifyContent={"center"}
          my={"5%"}
          width={"100%"}
        >
          {/* TODO: option for per month */}
          <Flex flexDir={"column"}>
            <Select
              ml={"auto"}
              mr={"9%"}
              backgroundColor={"white"}
              height={"30px"}
              width={"140px"}
              placeholder="Per Month"
              value={subscriptionOption}
              onChange={handleSubscriptionChange}
            >
              <option value="6">6 Months</option>
              <option value="12">Per Year</option>
            </Select>
            <Flex
              flexDir={{ base: "column", md: "column", lg: "row" }}
              mx={"40px"}
              mt={"40px"}
            >
              {/* Basic Paln */}
              <Flex
                ml={{ base: "0px", md: "0px", lg: "0px" }}
                flexDir={"column"}
              >
                <Text mx={"auto"} mb={"20px"}>
                  Basic Plan
                </Text>
                <Flex
                  mx={"auto"}
                  fontSize={{ base: "12px", md: "14px", lg: "15px" }}
                  flexDir={"column"}
                  borderTop={"9px solid #CAB683"}
                  borderRadius={"10px"}
                  boxShadow={"lg"}
                  backgroundColor={"white"}
                  height={"100%"}
                  width={"fit-content"}
                  p={5}
                  justifyContent={"space-between"}
                >
                  <Flex flexDir={"column"}>
                    <Text
                      color={"#CAB683"}
                      fontSize={{ base: "30px", md: "30px", lg: "35px" }}
                      mt={"50px"}
                      mx={"auto"}
                    >
                      {FormatIndianRupees(
                        calculatePremiumPlanPrice().shopdiscountedPrice
                      )}
                    </Text>
                    <Text
                      color={"#CAB683"}
                      fontSize={{ base: "20px", md: "20px", lg: "25px" }}
                      mt={"10px"}
                      mx={"auto"}
                    >
                      {Discount}% Discount
                    </Text>
                    <Text mt={"20px"} mx={"auto"}>
                      ✔ 1 Profile Picture
                    </Text>
                    <Text mt={"20px"} mx={"auto"}>
                      ✔ 6 Images of Shop
                    </Text>
                    <Text mt={"20px"} mx={"auto"}>
                      ✔ 3 Offer Images
                    </Text>
                    <Text mt={"20px"} mx={"auto"}>
                      ✔ 45 Product Limit
                    </Text>
                    <Text mt={"20px"} mx={"auto"}>
                      ✔ 5 Pending 3D Model requests at a time
                    </Text>
                    <Text mb={"20px"} mt={"10px"} mx={"auto"}>
                      (360 degree view will be provided for
                      <br />
                      products with 3D request)
                    </Text>
                  </Flex>
                  <Flex alignSelf={"center"}>
                    {(subscription === "premium" || !subscription) &&
                    ((shop && shop[0]?.product?.length > 45) ||
                      (shop && shop[0]?.offer.length > 3)) ? (
                      <CommonModal
                        modalState={modalClose}
                        btn={{
                          text: "red",
                          bg: "transparent",
                          hoverBg: "transparent",
                        }}
                        btnContent={
                          <Flex width={"100%"}>
                            <Button
                              justifySelf={"center"}
                              alignSelf={"center"}
                              boxShadow={"lg"}
                              mb={"10px"}
                              height={"30px"}
                              mt={"auto"}
                              backgroundColor={"#CAB683"}
                              mx={"auto"}
                              disabled={loading}
                            >
                              Buy Now
                            </Button>
                          </Flex>
                        }
                        modalContent={<DeleteProductNOffer />}
                      />
                    ) : (
                      <Button
                        boxShadow={"lg"}
                        mb={"10px"}
                        height={"30px"}
                        mt={"auto"}
                        backgroundColor={"#CAB683"}
                        mx={"auto"}
                        disabled={loading}
                        onClick={() => {
                          setPlan("basic");
                          loadRazorpay(
                            calculatePremiumPlanPrice().shopdiscountedPrice,
                            "basic"
                          );
                        }}
                      >
                        Buy Now
                      </Button>
                    )}
                  </Flex>
                </Flex>
              </Flex>

              {/* Premium Paln */}
              <Flex
                mt={{ base: "30px", md: "30px", lg: "0px" }}
                ml={{ base: "0px", md: "0px", lg: "50px" }}
                flexDir={"column"}
              >
                <Text mx={"auto"} mb={"20px"}>
                  Premium Plan
                </Text>
                <Flex
                  p={5}
                  mx={"auto"}
                  fontSize={{ base: "12px", md: "14px", lg: "15px" }}
                  flexDir={"column"}
                  borderTop={"9px solid #83C6CA"}
                  borderRadius={"10px"}
                  boxShadow={"lg"}
                  backgroundColor={"white"}
                  height={"fit-content"}
                  width={"fit-content"}
                >
                  <Text
                    color={"#83C6CA"}
                    fontSize={{ base: "30px", md: "30px", lg: "35px" }}
                    mt={"50px"}
                    mx={"auto"}
                  >
                    {FormatIndianRupees(
                      calculatePremiumPlanPrice().discountedPrice
                    )}
                  </Text>
                  <Text
                    color={"#83C6CA"}
                    fontSize={{ base: "20px", md: "20px", lg: "25px" }}
                    mt={"10px"}
                    mx={"auto"}
                  >
                    {Discount}% Discount
                  </Text>

                  <Text mt={"20px"} mx={"auto"}>
                    ✔ 1 Cover Picture
                  </Text>

                  <Text mt={"20px"} mx={"auto"}>
                    ✔ 1 Profile Picture
                  </Text>

                  <Text mt={"20px"} mx={"auto"}>
                    ✔ 6 Images of Shop
                  </Text>

                  <Text mt={"20px"} mx={"auto"}>
                    ✔ 1 Latest Promo video
                  </Text>

                  <Text mt={"20px"} mx={"auto"}>
                    ✔ 10 Offer Images
                  </Text>

                  <Text mt={"20px"} mx={"auto"}>
                    ✔ 100 Product Limit
                  </Text>

                  <Text mt={"20px"} mx={"auto"}>
                    ✔ 10 Pending 3D Model requests at a time
                  </Text>
                  <Text mb={"20px"} mt={"10px"} mx={"auto"}>
                    (360 degree view will be provided for
                    <br />
                    products with 3D request)
                  </Text>

                  <Text mb={"20px"} mt={"10px"} mx={"auto"}>
                    * Preference to appear the
                    <br />
                    shop name in home page.
                  </Text>

                  <Text mb={"20px"} mt={"10px"} mx={"auto"}>
                    * Top preference in filter and
                    <br />
                    search options.
                  </Text>

                  <Text mb={"20px"} mt={"10px"} mx={"auto"}>
                    * Preference to boost your
                    <br />
                    Offer images in home page
                  </Text>

                  <Button
                    boxShadow={"lg"}
                    mb={"10px"}
                    height={"30px"}
                    mt={"auto"}
                    backgroundColor={"#83C6CA"}
                    mx={"auto"}
                    disabled={loading}
                    // onClick={handlePremiumButtonClick}
                    onClick={() => {
                      setPlan("premium");
                      loadRazorpay(
                        calculatePremiumPlanPrice().discountedPrice,
                        "premium"
                      );
                    }}
                  >
                    Buy Now
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ShopSubscription;
