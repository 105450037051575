import {
  Box,
  Container,
  Flex,
  Input,
  InputGroup,
  Text,
} from "@chakra-ui/react";
import React from "react";
import Navbar from "../Navbar";
import SearchBar from "./SearchBar";
import { State } from "../../Context/Context";
function Header(props) {
  const {
    collection,
    heading,
    image,
    imageMob,
    enddate,
    search,
    mt = "1px",
    bgSize = { base: "100% 250px", md: "100% 320px" },
    height = { base: "250px", md: "320px" },
    dashboard,
    subscription,
    designer,
  } = props;
  const { selection } = State();
  // Function to format the end date to day-month-year
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };
  return (
    <Flex
      flexDir={"column"}
      alignItems={"center"}
      bgImage={{ base: imageMob, md: image }}
      bgSize={bgSize}
      width={"100%"}
      height={height}
      bgRepeat={"no-repeat"}
      mb={10}
    >
      <Navbar />
      <Text
        pt={{ base: "45px", md: "30px" }}
        mt={mt}
        align={"center"}
        fontFamily={"heading"}
        fontSize={{ base: "30px", md: "40px" }}
        color={"#FEFBF4"}
        style={{ textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)" }}
      >
        {heading}
      </Text>
      {dashboard && (
        <>
          <Flex
            bg={
              subscription === "basic" || (designer && !subscription)
                ? "#d4ddac"
                : subscription === "premium"
                ? "#C5ACDD"
                : "white"
            }
            borderRadius={"6px"}
            boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
            px={2}
            py={1}
            width={"fit-content"}
            mx={"auto"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Text fontWeight={"bold"} color={"blackAlpha.500"}>
              {subscription === "basic" || (designer && !subscription)
                ? "Basic Member"
                : subscription === "premium"
                ? "Premium Member"
                : subscription === "default"
                ? "Demo Version"
                : "Not Subscribed"}
            </Text>
          </Flex>
          {!designer && (
            <Text
              mt={"10px"}
              fontSize={{ base: "13px", md: "13px", lg: "14px" }}
              color={"white"}
            >
              {subscription === "basic"
                ? `Basic subscription valid till : ${formatDate(enddate)}`
                : subscription === "premium"
                ? `Premium subscription valid till : ${formatDate(enddate)}`
                : subscription === "default"
                ? `Basic plan free for one month, valid till: ${formatDate(
                    enddate
                  )}`
                : "Not Subscribed"}
            </Text>
          )}

          {designer && (
            <Text
              mt={"10px"}
              fontSize={{ base: "13px", md: "13px", lg: "14px" }}
              color={"white"}
            >
              {subscription === "basic"
                ? ""
                : subscription === "premium"
                ? `Premium subscription valid till : ${formatDate(enddate)}`
                : ""}
            </Text>
          )}

          {!subscription && !designer && (
            <Text
              bg={"white"}
              // mt={"10px"}
              py={1}
              px={"10px"}
              border={"1px solid brown"}
              boxShadow={"lg"}
              borderRadius={4}
              color={"red.500"}
              fontWeight={"bold"}
            >
              Your subscription has expired. Please subscribe to continue using
              more of our services.
            </Text>
         )} 
        </>
      )}
      {search && (
        <Box pt={{ base: 0, md: 8 }}>
          <SearchBar width={"100%"} />
        </Box>
      )}
      {selection && collection && (
        <Box
          mt={{ base: "30px", md: "50px", lg: "130px" }}
          boxShadow={"0px 4px 13px 0px rgba(0, 0, 0, 0.57)"}
          borderRadius={20}
          bg={"brand.btnBg"}
          p={2}
          px={6}
        >
          <Text fontSize={"18px"} fontFamily={"heading"} color={"white"}>
            {selection == "furniture"
              ? "Furniture & Storage"
              : selection == "wallArt"
              ? "Wall Art"
              : selection == "lighting"
              ? "Lighting & Fan"
              : selection == "decoratives"
              ? "Decoratives"
              : selection == "furnishing"
              ? "Furnishing"
              : selection == "tile"
              ? "Tile"
              : selection == "veneer"
              ? "Veneer/Laminate"
              : selection == "sanitary"
              ? "Sanitary"
              : selection == "kitchen"
              ? "Kitchen"
              : ""}
          </Text>
        </Box>
      )}
    </Flex>
  );
}

export default Header;
