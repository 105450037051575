import { Flex, Text, Box, Container } from "@chakra-ui/layout";
import { Divider, AbsoluteCenter, Image, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { stores } from "../../Stores/SingleStores/DummyStoreData";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  EffectCoverflow,
} from "swiper/modules";
import { useNavigate } from "react-router-dom";
import storebg from "../../../Assets/Images/MainPage/storebg.png";
import StoresCollection from "../../Stores/StoresCollection/StoresCollection";
import { getStores } from "../../../Functions/GetApiCalls";

function StoresAvailable() {
  const navigate = useNavigate();
    // Function to open single stores page
    const OpenStorePage = (item) => {
      window.scrollTo(0, 0);
      navigate(`/stores/${item.shopname}#singlestoreinfo`, { state: { item: item } });
    };
  const [loading, setLoading] = useState();
  const [storesData, setStoresData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getStores(setStoresData);
      setLoading(false);
    };
    fetchData();
  }, []);
  return (
    <Flex
      width={"100%"}
      flexDirection={"column"}
      backgroundRepeat={"no-repeat"}
      backgroundSize={"cover"}
      backgroundImage={storebg}
    >
      {/* .....Heading.... */}
      <Container>
        <Flex width={"100%"} justifyContent={"center"}>
          <Flex
  
            maxWidth={{ base: "200px", md: "800px", lg: "800px" }}
            flexDirection={"column"}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Text
              justifyContent={"center"}
              textAlign={"center"}
              mt={"50px"}
              fontSize={{ base: "22px", md: "30px", lg: "32px" }}
              fontFamily={"heading"}
            >
              Stores Available
            </Text>
            <Divider border={"2px solid #E3B78B"} />
          </Flex>
        </Flex>
        {/* ..........carousel............. */}
        <Flex
          mb={"30px"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {loading ? (
            <>
              <Spinner />
            </>
          ) : (
            <Flex width={"82vw"}>
              <Swiper
                effect={"coverflow"}
                grabCursor={"true"}
                centeredSlides={"true"}
                loop={"true"}
                slidesPerView={4}
                // slidesPerView={4}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifiers: 1,
                  slideShadows: false,
                }}
                className="storeSlider"
                // slidesPerView={4}
                navigation={true}
                pagination={false}
                mousewheel={false}
                keyboard={true}
                breakpoints={{
                  1629: {
                    slidesPerView: 4,
                  },
                  1340: {
                    slidesPerView: 4,
                  },

                  1051: {
                    slidesPerView: 3,
                  },
                  833: {
                    slidesPerView: 3,
                  },
                  390: {
                    slidesPerView: 1,
                  },
                  375: {
                    slidesPerView: 1,
                  },
                  344: {
                    slidesPerView: 1,
                  },
                }}
                modules={[
                  Navigation,
                  Pagination,
                  Mousewheel,
                  Keyboard,
                  EffectCoverflow,
                ]}
              >
                {storesData?.map((storeitem, index) => (
                  <SwiperSlide key={index}>
                    <Flex
                   
                    mx={"auto"}
                    width={{base:"220px",md:"220px",lg:"300px"}}
                      mt={{ base: "90px", md: "190px", lg: "190px" }}
                      fontSize={{ base: "19px", md: "20px", lg: "20px" }}
                      justifyContent={"center"}
                      alignItems={"center"}
                      flexDir={"column"}
                      onClick={() => OpenStorePage(storeitem)} cursor={"pointer"}
                    >
                      <StoresCollection
                        boxShadow={"dark-lg"}
                        imageHeight={{
                          base: "220px",
                          md: "260px",
                          lg: "280px",
                        }}
                        imageWidth={{ base: "200px", md: "240px", lg: "260px" }}
                        src={storeitem.shopprofileimage}
                        heading={storeitem.shopname}
                        description={storeitem.addressLine3}
                      />
                    </Flex>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Flex>
          )}
        </Flex>
      </Container>
    </Flex>
  );
}

export default StoresAvailable;
